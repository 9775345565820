import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"

export const Head = () => <Seo title="Message envoyé" noindex />

const ConfirmationPage = () => (
  <Layout content="page">
    <h1>Votre message a bien été envoyé</h1>
    <p>Merci beaucoup, nous reviendrons vers vous rapidement !</p>
    <p>
      <Link to="/" title="My tour of Europe">
        Revenir à l'accueil
      </Link>
    </p>
  </Layout>
)

export default ConfirmationPage
